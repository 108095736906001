import React from 'react';
import useMainPopup from './hooks/useMainPopup';
import { MainPopupContent } from '@display-component/pc';
import { Box, HStack, NewTextButton } from '@design-system/pc';
import { ALERT_Z_INDEX } from '@/styles';

const MAIN_POPUP_CONTENT_WIDTH = 418;
const MAIN_POPUP_CONTENT_HEIGHT = 300;
const MAIN_POPUP_CONTROL_BAR_HEIGHT = 56;

export interface MainProps {}

const MainPopup = () => {
  const {
    isOpen,
    isReadyData,
    handleClickContent,
    handleClosePopup,
    handleCloseToday,
    handleSyncData,
  } = useMainPopup();

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      display={isReadyData ? 'block' : 'none'}
      position="fixed"
      left={0}
      bottom={0}
      width={MAIN_POPUP_CONTENT_WIDTH}
      zIndex={ALERT_Z_INDEX}
      height={MAIN_POPUP_CONTENT_HEIGHT + MAIN_POPUP_CONTROL_BAR_HEIGHT}
    >
      <Box
        overflow="hidden"
        borderRadius="16px 16px 0 0"
        width={MAIN_POPUP_CONTENT_WIDTH}
        height={MAIN_POPUP_CONTENT_HEIGHT}
      >
        <MainPopupContent
          handleClick={(appLink) => handleClickContent(appLink?.linkUrl)}
          width={MAIN_POPUP_CONTENT_WIDTH}
          height={MAIN_POPUP_CONTENT_HEIGHT}
          handleIsOnData={handleSyncData}
        />
      </Box>
      <HStack
        height={MAIN_POPUP_CONTROL_BAR_HEIGHT}
        p="18px 16px"
        space="space-between"
        backgroundColor="color_chip.white"
      >
        <NewTextButton
          size="md"
          color="color_chip.primary"
          onClick={handleCloseToday}
        >
          오늘 하루 보지 않기
        </NewTextButton>
        <NewTextButton
          size="md"
          color="color_chip.primary"
          onClick={handleClosePopup}
        >
          닫기
        </NewTextButton>
      </HStack>
    </Box>
  );
};

export default React.memo(MainPopup);
