import React from 'react';
import { Box, BoxProps } from '@design-system/pc';
import styled from 'styled-components';

export interface ClickableBoxProps {
  children: React.ReactNode;
}

const ClickableBox = ({
  children,
  ...props
}: ClickableBoxProps & Omit<BoxProps, 'as' | 'children'>) => {
  return <PointerBox {...props}>{children}</PointerBox>;
};

const PointerBox = styled(Box)`
  cursor: pointer;
`;

export default ClickableBox;
