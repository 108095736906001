import { CookieParseOptions, CookieSerializeOptions } from 'cookie';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

const set = <Value>(
  key: string,
  value: Value,
  options: CookieSerializeOptions,
): void => {
  setCookie(null, key, value as unknown as string, {
    path: '/',
    httpOnly: false,
    sameSite: 'lax',
    secure: false,
    domain: '',
    ...options,
  });
};

const get = <Value = string>(
  key: string,
  options?: CookieParseOptions,
): Value => {
  return parseCookies(null, { ...options })[key] as unknown as Value;
};

const remove = (key: string, options?: CookieSerializeOptions): void => {
  destroyCookie(null, key, {
    maxAge: -1,
    domain: '',
    path: '/',
    ...options,
  });
};

const cookieHandler = () => {
  return {
    set,
    get,
    remove,
  };
};

export default cookieHandler;
